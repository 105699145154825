// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-landscaping-js": () => import("./../../../src/pages/landscaping.js" /* webpackChunkName: "component---src-pages-landscaping-js" */),
  "component---src-pages-lawn-care-js": () => import("./../../../src/pages/lawn-care.js" /* webpackChunkName: "component---src-pages-lawn-care-js" */),
  "component---src-pages-lawn-tips-js": () => import("./../../../src/pages/lawn-tips.js" /* webpackChunkName: "component---src-pages-lawn-tips-js" */),
  "component---src-pages-other-services-js": () => import("./../../../src/pages/other-services.js" /* webpackChunkName: "component---src-pages-other-services-js" */),
  "component---src-pages-snow-removal-js": () => import("./../../../src/pages/snow-removal.js" /* webpackChunkName: "component---src-pages-snow-removal-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

